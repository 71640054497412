body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.screen {
  background-color: #F2F4F5;
  border: 2px solid #eee;
  width: 800px;
  height: 600px;
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
}
.table {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-image: linear-gradient(to right, #565655 , #B2B2B1);
}
.top-info {
  position: absolute;
  top:20px;
  width: 50%;
  left: 25%;
  
  font-size: 1.5rem;
}

.top-title {
  font-weight: bold;
  color: #F8710E;
}
.scara-txt {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
}
.scara-txt > span:first-child {
  flex-grow: 1;
}
.scara-turnesol {
  width: 100%;
  height: 30px;
  background-image: linear-gradient(to right, #FF1200 10%, #0B0791 );
}

.eprubeta {
  width: 120px;
  position: absolute;
  bottom: 30px;
  left: 80px;
  opacity: 0.7;
  z-index: 2;
}

.precipitat {
  position: absolute;
  width:25px;
  height: auto;
  bottom: 145px;
  left:169px;
  z-index: 2;
  opacity: 0;
  /* animation-name: updown; */
  animation-duration: 1s;
  animation-iteration-count: 3;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

@keyframes updown {
  50% {bottom: 145px;}
  50% {bottom: 150px;}
}

.eprubeta-content {
  position: absolute;
  bottom: 120px;
  left: 168px;
  opacity: 0.2;
  width: 27px;
  height: 0;
  overflow: hidden;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  z-index: 2;
  background-color: rgb(177, 159, 159);
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}


.sticla1 {
  width: 70px;
  position: absolute;
  bottom: 24px;
  left: 560px;
  z-index: 2;
}

.sticla1 .sticla {
  width: 100%;
  height: auto;
  opacity: 0.7;
} 
.sticla1 .dop {
  position: absolute;
  top:-19px;
  width: 30px;
  height: auto;
  left: 19px;
}
.sticla2 {
  width: 70px;
  position: absolute;
  bottom: 24px;
  left: 460px;
  z-index: 2;
}

.sticla2 .sticla {
  width: 100%;
  height: auto;
  opacity: 0.7;
} 
.sticla2 .dop {
  position: absolute;
  top:-8px;
  width: 29px;
  height: auto;
  left: 20px;
}

.sticla-content {
  position: absolute;
  bottom: 5px;
  opacity: 0.2;
  background-color: #222;
  width: calc(100% - 6px);
  margin-left: 3px;
  margin-right: 3px;
  height: 70px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.pipeta1 {
  position: absolute;
  bottom: 60px;
  left:584px; 
  width: 20px;
  cursor:grab;
}

.pipeta2 {
  position: absolute;
  bottom: 60px;
  left:484px; 
  width: 20px;
  cursor:grab;
}

.pipeta1 .pipeta-goala, .pipeta2 .pipeta-goala {
  width: 100%;
  height: auto;
}

.pipeta-content {
  position: absolute;
  width: 5px;
  height: 0;
  background-color: #111;
  bottom: 5px;
  left: 7px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  opacity: 0.5;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.sticla-text {
  position: absolute;
  bottom: 30px;
  width: 50px;
  margin-left: 5px;
  border: 2px solid #000;
  border-radius: 2px;
  padding: 3px;
  font-weight: bold;
}

.box {
  position: absolute;
  cursor: move;
  color: black;
  max-width: 215px;
  border-radius: 5px;
  padding: 1em;
  margin: auto;
  user-select: none;
}

